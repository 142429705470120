import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';

import Analytics from '@hh.ru/analytics-js';
import { formatToReactComponent, type TranslatedComponent, translation, useSelector } from '@hh.ru/front-static-app';
import { useCaptcha } from '@hh.ru/hhcaptcha';
import { VSpacingContainer, Title, Text, Button } from '@hh.ru/magritte-ui';

import Captcha from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/Captcha';
import {
    ApplicantLoginFormValues,
    AuthCredentialType,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    OtpTypeMap,
    PASSWORD_FIELD,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import useOtp from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp';

import PasswordField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterPasswordStep/PasswordField';

const TrlKeys = {
    title: 'applicant.login.step.enterPassword.title',
    subtitle: {
        common: 'applicant.login.step.enterPassword.subtitle',
        [AuthCredentialType.Phone]: 'applicant.login.step.enterPassword.subtitle.phone',
        [AuthCredentialType.Email]: 'applicant.login.step.enterPassword.subtitle.email',
    },
    action: {
        login: 'applicant.login.step.enterPassword.action.login',
        loginWithCode: 'applicant.login.step.enterPassword.action.authWithCode',
    },
};

const EnterPasswordStep: TranslatedComponent = ({ trls }) => {
    const ref = useRef<HTMLDivElement>(null);

    const vacancyId = useSelector((state) => state.postponedActions.vacancy?.vacancyId);

    const { onNextStep } = useApplicantLoginContext();

    const { mutators, getState, submit } = useForm<ApplicantLoginFormValues>();
    const { credentialType, username } = getState().values;

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { sendOtpCode } = useOtp();
    const { resetCaptcha } = useCaptcha();

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'applicant_authorization',
                type: 'by_password',
                vacancyId,
            });
        }
    }, [vacancyId]);

    useEffect(() => {
        return () => {
            mutators.removeValue(PASSWORD_FIELD);
            resetCaptcha();
        };
    }, [mutators, resetCaptcha]);

    const handleLoginButtonClick = async (): Promise<void> => {
        setIsSubmitting(true);
        await submit();
        setIsSubmitting(false);
    };

    const handleLoginWithCodeButtonClick = async (): Promise<void> => {
        const { credentialType, username } = getState().values;

        setIsSubmitting(true);
        await sendOtpCode({ otpType: OtpTypeMap[credentialType], login: username });
        setIsSubmitting(false);

        onNextStep('enter-otp-code');
    };

    return (
        <div ref={ref}>
            <VSpacingContainer default={24}>
                <Title
                    Element="h2"
                    size="medium"
                    alignment="center"
                    description={
                        <Text Element="p" typography="paragraph-2-regular" style="secondary">
                            {formatToReactComponent(trls[TrlKeys.subtitle.common], {
                                '{0}': trls[TrlKeys.subtitle[credentialType]],
                                '{1}': <Text Element="span">{username}</Text>,
                            })}
                        </Text>
                    }
                >
                    {trls[TrlKeys.title]}
                </Title>
                <PasswordField />
                <Captcha />
                <VSpacingContainer default={12}>
                    <Button
                        size="large"
                        mode="primary"
                        style="accent"
                        disabled={isSubmitting}
                        onClick={handleLoginButtonClick}
                    >
                        {trls[TrlKeys.action.login]}
                    </Button>
                    <Button
                        size="large"
                        mode="secondary"
                        style="accent"
                        disabled={isSubmitting}
                        onClick={handleLoginWithCodeButtonClick}
                    >
                        {trls[TrlKeys.action.loginWithCode]}
                    </Button>
                </VSpacingContainer>
            </VSpacingContainer>
        </div>
    );
};

export default translation(EnterPasswordStep);
