import { type ComponentProps } from 'react';
import { Field } from 'react-final-form';

import { type TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { PasswordInput } from '@hh.ru/magritte-ui';

import { type ApplicantLoginFormValues } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    MISSING_VALUE_ERROR,
    PASSWORD_FIELD,
    required,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { LoginByPasswordErrorKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import useAutofocus from 'src/hooks/useAutofocus';

const TrlKeys = {
    [MISSING_VALUE_ERROR]: 'form.error.valueMissing',
    [LoginByPasswordErrorKey.BadCredentials]: 'error.bad.credentials',
    [LoginByPasswordErrorKey.Captcha]: 'error.signup.captcha.invalid',
    [LoginByPasswordErrorKey.Unknown]: 'form.error.default',
};

const PasswordField: TranslatedComponent = ({ trls }) => {
    const autofocusRef = useAutofocus();

    const getErrorMessage = (error?: keyof typeof TrlKeys): string | null => {
        if (!error) {
            return null;
        }

        return trls[TrlKeys[error]];
    };

    return (
        <Field<ApplicantLoginFormValues['password']>
            name={PASSWORD_FIELD}
            type="text"
            validate={required}
            render={({ input, meta }) => {
                const submitError = meta.dirtySinceLastSubmit ? null : (meta.submitError as string);
                const error = (meta.error || submitError) as keyof typeof TrlKeys;
                const errorMessage = getErrorMessage(error);

                return (
                    <PasswordInput
                        {...(input as ComponentProps<typeof PasswordInput>)}
                        ref={autofocusRef}
                        size="large"
                        invalid={meta.touched && !!errorMessage}
                        errorMessage={errorMessage}
                        autoCapitalize="off"
                        autoCorrect="off"
                        spellCheck={false}
                        onBlur={undefined}
                    />
                );
            }}
        />
    );
};

export default translation(PasswordField);
