import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';

import Analytics from '@hh.ru/analytics-js';
import type { TranslatedComponent } from '@hh.ru/front-static-app';
import { translation, useSelector } from '@hh.ru/front-static-app';
import { useCaptcha } from '@hh.ru/hhcaptcha';
import { VSpacingContainer, Button, Title } from '@hh.ru/magritte-ui';

import Captcha from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/Captcha';
import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import { OtpTypeMap } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import { useDisableSocialNetworkAuthExp } from 'src/hooks/useDisableSocialNetworkAuthExp';
import useIsIframeView from 'src/hooks/useIsIframeView';
import useSmsRegistration from 'src/hooks/useSmsRegistration';

import CredentialField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/CredentialField';
import CredentialTypeSwitch from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/CredentialTypeSwitch';
import LegalInfo from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/LegalInfo';
import SocialLoginButton from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/SocialLoginButton';
import { FlowKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/types';

const TrlKeys = {
    [FlowKey.SignIn]: {
        title: 'applicant.login.step.enterCredentials.title.signIn',
        action: {
            next: 'applicant.login.step.enterCredentials.action.next',
            loginWithPassword: 'applicant.login.step.enterCredentials.action.authWithPassword',
        },
    },
    [FlowKey.SignUp]: {
        title: 'applicant.login.step.enterCredentials.title.signUp',
        action: {
            next: 'applicant.login.step.enterCredentials.action.next',
        },
    },
};

const EnterCredentialsStep: TranslatedComponent = ({ trls }) => {
    const ref = useRef<HTMLDivElement>(null);

    const socialNetworks = useSelector((state) => state.loginForm.socialNetworks);
    const vacancyId = useSelector((state) => state.postponedActions.vacancy?.vacancyId);

    const { flow, onNextStep } = useApplicantLoginContext();

    const { getState, getFieldState, blur, submit } = useForm<ApplicantLoginFormValues>();
    const { credentialType: initialCredentialType } = getState().values;

    const [credentialType, setCredentialType] = useState(initialCredentialType);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isIframeView = useIsIframeView();
    const isDisableSocialNetworkAuthExp = useDisableSocialNetworkAuthExp();
    const { isSmsRegistrationAllowed, allowedCountries, countryCode } = useSmsRegistration();
    const { resetCaptcha } = useCaptcha();

    useEffect(() => {
        if (ref.current) {
            Analytics.sendHHEventElementShown(ref.current, {
                name: 'applicant_authorization',
                type: 'by_code',
                vacancyId,
                availableOptions: socialNetworks?.length
                    ? JSON.stringify(socialNetworks.map(({ code }) => code.toLowerCase()))
                    : null,
            });
        }
    }, [vacancyId, socialNetworks]);

    const flowKey = flow === 'sign-in' ? FlowKey.SignIn : FlowKey.SignUp;
    const shouldShowSocialNetworks = !!socialNetworks?.length && !isIframeView && !isDisableSocialNetworkAuthExp;

    const isUsernameFieldValid = (): boolean => Boolean(!getFieldState('username')?.error);

    const handleCredentialTypeChange = (value: AuthCredentialType): void => {
        Analytics.sendHHEventButtonClick('email_phone_switch', {
            switchTo: OtpTypeMap[value],
        });
        setCredentialType(value);
    };

    const handleNextButtonClick = async (): Promise<void> => {
        Analytics.sendHHEventButtonClick('authorize_by_code', {
            vacancyId,
        });

        setIsSubmitting(true);
        const submitErrors = await submit();
        setIsSubmitting(false);

        if (isUsernameFieldValid() && !submitErrors?.username) {
            onNextStep('enter-otp-code');
        }
    };

    const handleLoginWithPasswordButtonClick = (): void => {
        Analytics.sendHHEventButtonClick('authorize_by_password', {
            vacancyId,
        });

        // Выполняем клиентскую валидацию перед переходом к форме пароля
        blur('username');

        if (isUsernameFieldValid()) {
            resetCaptcha();
            onNextStep('enter-password');
        }
    };

    const handleSocialLoginButtonClick = (): void => {
        onNextStep('social-login');
    };

    return (
        <div ref={ref}>
            <VSpacingContainer default={24}>
                <Title Element="h2" size="medium" alignment="center">
                    {trls[TrlKeys[flowKey].title]}
                </Title>
                {isSmsRegistrationAllowed && <CredentialTypeSwitch onChange={handleCredentialTypeChange} />}
                {/* Меняем key для перемонтирования компонента при смене credentialType
                для очистки поля ввода от ошибки и корректного автофокуса */}
                <CredentialField
                    key={credentialType}
                    credentialType={credentialType}
                    defaultCountry={countryCode}
                    allowedCountries={allowedCountries}
                    showPlaceholder={!isSmsRegistrationAllowed}
                />
                <Captcha />
                <VSpacingContainer default={12}>
                    <Button
                        mode="primary"
                        size="large"
                        style="accent"
                        disabled={isSubmitting}
                        onClick={handleNextButtonClick}
                    >
                        {trls[TrlKeys[flowKey].action.next]}
                    </Button>
                    {flow === 'sign-in' && (
                        <Button
                            mode="secondary"
                            size="large"
                            style="accent"
                            disabled={isSubmitting}
                            onClick={handleLoginWithPasswordButtonClick}
                        >
                            {trls[TrlKeys[FlowKey.SignIn].action.loginWithPassword]}
                        </Button>
                    )}
                </VSpacingContainer>
                {shouldShowSocialNetworks && (
                    <SocialLoginButton socialNetworks={socialNetworks} onClick={handleSocialLoginButtonClick} />
                )}
                <LegalInfo />
            </VSpacingContainer>
        </div>
    );
};

export default translation(EnterCredentialsStep);
