import Analytics from '@hh.ru/analytics-js';
import { type AuthType as CodeConfirmationFormAuthType } from '@hh.ru/analytics-js-events/build/xhh/common/authorization/code_confirmation/code_confirmation_form_submit';
import { type AuthType as EnterFullNameFormAuthType } from '@hh.ru/analytics-js-events/build/xhh/common/authorization/enter_full_name/enter_full_name_form_submit';
import { urlParser } from '@hh.ru/browser-api-utils';
import { useSelector } from '@hh.ru/front-static-app';

import useLogin from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin';
import { LoginByCodeErrorKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import useOtp from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp';
import useSignUp from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useSignUp';
import type {
    SignUpError,
    SignUpErrorKey,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useSignUp/types';
import type { ApplicantLoginStep } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';
import useSmsRegistration from 'src/hooks/useSmsRegistration';
import type { AccountType } from 'src/models/accountLogin';
import type { Verification } from 'src/models/applicant/auth';
import { OtpOperationType } from 'src/models/otp';

import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    defaultValues,
    OtpTypeMap,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';

const APPLICANT_ACCOUNT_TYPE: AccountType = 'APPLICANT';

interface UseApplicantLoginFormParams {
    step: ApplicantLoginStep | null;
    isSignupPage: boolean;
    onVerificationChange: (value: Verification) => void;
    onCodeLengthChange: (value: number) => void;
}

interface UseApplicantLoginForm {
    initialValues: ApplicantLoginFormValues;
    onSubmit: (value: ApplicantLoginFormValues) => Promise<Partial<ApplicantLoginFormValues> | undefined>;
}

const useApplicantLoginForm = ({
    step,
    isSignupPage,
    onVerificationChange,
    onCodeLengthChange,
}: UseApplicantLoginFormParams): UseApplicantLoginForm => {
    const authParams = useSelector((state) => state.authUrl);
    const vacancyId = useSelector((state) => state.postponedActions?.vacancy?.vacancyId);
    const isMultiAccount = useSelector((state) => state.isMultiAccount);

    const { isSmsRegistrationAllowed } = useSmsRegistration();
    const { sendOtpCode } = useOtp();
    const { loginWithCode, loginWithPassword } = useLogin();
    const { signUp } = useSignUp();

    const backUrl = authParams?.backurl;

    const initialValues: UseApplicantLoginForm['initialValues'] = { ...defaultValues };

    const previousUsername = authParams?.['login-field-value-entered'] ?? authParams?.['login-field-value'];

    if (previousUsername) {
        initialValues.username = previousUsername;

        if (previousUsername.includes('@')) {
            initialValues.credentialType = AuthCredentialType.Email;
        }
    }

    if (!isSmsRegistrationAllowed) {
        initialValues.credentialType = AuthCredentialType.Email;
    }

    const sendCodeConfirmationEvent = (
        authType: CodeConfirmationFormAuthType = 'unknown',
        errorKey?: LoginByCodeErrorKey
    ): void => {
        const errors = errorKey ? [{ field: 'code_verification', description: errorKey }] : null;

        Analytics.sendHHEvent('form_submit', {
            formName: 'code_confirmation',
            authType,
            accountType: APPLICANT_ACCOUNT_TYPE,
            vacancyId,
            errors,
            isMultiAccount,
            isSignupPage,
        });
    };

    const sendEnterFullNameEvent = (authType: EnterFullNameFormAuthType = 'unknown', errorKeys?: SignUpError): void => {
        const { firstName, lastName } = errorKeys ?? {};
        const errors: { field: 'firstName' | 'lastName'; description: SignUpErrorKey }[] = [];

        if (firstName) {
            errors.push({ field: 'firstName', description: firstName });
        }

        if (lastName) {
            errors.push({ field: 'lastName', description: lastName });
        }

        Analytics.sendHHEvent('form_submit', {
            formName: 'enter_full_name',
            authType,
            vacancyId,
            errors: errors.length ? errors : null,
            isSignupPage,
        });
    };

    const onSubmit: UseApplicantLoginForm['onSubmit'] = async (values) => {
        const {
            credentialType,
            username: login,
            password,
            code = '',
            firstName,
            lastName,
            advAgreement,
            deleteInactiveTime,
        } = values;
        const otpType = OtpTypeMap[credentialType];

        if (step === 'enter-credentials') {
            const result = await sendOtpCode({ login, otpType, operationType: OtpOperationType.ApplicantOtpAuth });

            if (!result.success) {
                return { username: result.error };
            }

            onCodeLengthChange(result.data.codeLength);
        }

        if (step === 'enter-otp-code') {
            const result = await loginWithCode({ login, code, backUrl, accountType: APPLICANT_ACCOUNT_TYPE });

            if (!result.success) {
                const { key, data } = result.error;
                sendCodeConfirmationEvent(otpType, key);

                if (data?.verification) {
                    onVerificationChange(data.verification);
                }

                if (key === LoginByCodeErrorKey.EmployerNotAllowed && data?.employerLoginURL) {
                    window.location.assign(data.employerLoginURL);
                }

                return { code: key };
            }

            sendCodeConfirmationEvent(otpType);
            window.location.assign(result.data.backurl ?? '/');
        }

        if (step === 'enter-password') {
            const result = await loginWithPassword({
                url: authParams?.['login-url'],
                login,
                password,
                accountType: APPLICANT_ACCOUNT_TYPE,
                failUrl: authParams?.failurl,
            });

            if (!result.success) {
                return { password: result.error };
            }

            window.location.assign(result.data.redirectUrl ?? '/');
        }

        if (step === 'enter-name') {
            const result = await signUp({
                firstName,
                lastName,
                login,
                password,
                code,
                isSignupPage,
                deleteInactiveTime,
                backUrl,
                hasAdConsent: advAgreement,
            });

            if (!result.success) {
                sendEnterFullNameEvent(otpType, result.error);

                return result.error;
            }

            sendEnterFullNameEvent(otpType);

            const { pathname, search } = urlParser(backUrl ?? '/');
            window.location.assign(result.data.backurl ?? `${pathname}${search}`);
        }

        return undefined;
    };

    return { initialValues, onSubmit };
};

export default useApplicantLoginForm;
